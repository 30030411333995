.loginContainer {
}

.signInButton {
    opacity: 0;
    animation: fadein 1s linear 1s forwards;
    margin-top: 20px;
}

.textContainer {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
}

.title {
    animation: fadein 1s linear forwards;
    font-size: xx-large;
}

.subtitle {
    opacity: 0;
    animation: fadein 1s linear 0.5s forwards;
    font-size: large;
}

.backdrop {
    height: 100vh;
    width: 100vw;
    opacity: 0.5;
    cursor: default;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}