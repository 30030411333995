.plantModal {
    display: grid;
    grid-template-columns: 1fr 132px;
}

.plantAttributes {
    display: flex;
    flex-direction: column;
    padding: var(--SPACING-M);
}

.plantIcon {
    height: 100px;
    width: 100px;
    margin: var(--SPACING-M);
}

.label {
    padding-bottom: 10px;
}