.RoundPreviewWrapper,
.DeluxePreviewWrapper {
}

.OoblongPreviewWrapper {
    padding-bottom: 31.7%;
}

.RoundPreviewWrapper {
    width: 69%;
    padding-bottom: 31%;
}

.outerBorder {
    background-color: wheat;
    padding: 5px;
    height: 100%;
}

.innerBorder {
    background-color: peru;
    height: 100%;
}

.DeluxePatchGrid {
    display: grid;
    grid-template-areas:
    'p1 p2 p3'
    'p8 em p4'
    'p7 p6 p5';
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.OoblongPatchGrid {
    display: grid;
    grid-template-areas:
    'p1 p2 p3'
    'p6 p5 p4';
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}


.RoundPatchGrid {
    display: grid;
    grid-template-areas:
    'p1 p2'
    'p4 p3';
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.RoundPatchBed,
.OoblongPatchBed,
.DeluxePatchBed {
    display: flex;
    border: 2px dashed wheat;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 5%;
    position: relative;
}
.clickableBed:nth-child(1) {
    grid-area: p1;
}

.clickableBed:nth-child(2) {
    grid-area: p2;
}

.clickableBed:nth-child(3) {
    grid-area: p3;
}

.clickableBed:nth-child(4) {
    grid-area: p4;
}

.clickableBed:nth-child(5) {
    grid-area: p5;
}

.clickableBed:nth-child(6) {
    grid-area: p6;
}

.clickableBed:nth-child(7) {
    grid-area: p7;
}

.clickableBed:nth-child(8) {
    grid-area: p8;
}

.clickableBed:nth-child(9) {
    grid-area: em;
}

.emptyBed {
    border-radius: 20%;
    background-color: saddlebrown;
    width: 100%;
    padding-bottom: 80%;
    margin: 10%;
}

.plantIconBed {
    position: relative;
    width: auto;
    padding: 10%;
    border-radius: 10%;
    height: 100%;
}

.fertilizeProgressIcon {
    position: absolute;
    bottom: 0;
    left: 0;
}

.waterProgressIcon {
    position: absolute;
    bottom: 0;
    right: 70%;
}

.growProgressIcon {
    position: absolute;
    bottom: 0;
    right: 35%;
}

ion-icon {
    left: 0;
}

.isGrown {
    background-color: #2dd36f;
}

.isWilting {
    background-color: var(--ion-color-wilt);
}

.isDead {
    background-color: FireBrick;
}

.isDead > img {
    filter: grayscale(100%);
}

.clickableBed {
    background-color: transparent;
    border: none;
    padding: 0;
    aspect-ratio: 1;
}

.plantProgressContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px;
}

.plantProgressContainer ion-progress-bar {
    margin-top: 1px;
    height: 3px;
}

.plantProgressContainer ion-progress-bar:first-child {
    --background: black;
}