.pageBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons {
    width: min(400px, 100%);
    display: flex;
    justify-content: center;
    margin-top: 80px;
}