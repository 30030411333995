.modal {
    --backdrop-opacity: 0.5;
}

@media only screen and (min-width: 400px) and (min-height: 720px) {
    .modal {
        --width: 80%;
        --height: 365px;
    }
}

@media only screen and (min-width: 720px) and (min-height: 720px) {
    .modal {
        --width: 600px;
        --height: 365px;
    }
}

.plantModal {
    display: grid;
    grid-template-columns: 1fr 132px;
}

.plantAttributes {
    display: flex;
    flex-direction: column;
    padding: var(--SPACING-M);
}

.plantIcon {
    height: 100px;
    width: 100px;
    margin: var(--SPACING-M);
}

.label {
    padding-bottom: 10px;
}