.pageBody {
    height: 100%;
    padding-bottom: 56px;
}

.pageBody > ion-content {
    --offset-top: 0px !important;
    --offset-bottom: 0px !important;
}

.plantPageBody {
    height: 100%;
    padding-bottom: 80px;
}

.PlantPageModal {
    --height: 100%;
    --width: 420px;
}

.pageWrapper {
    padding: 20px;
    width: min(400px, 100%);
    margin-left: 5px;
}

.emptyBed2 {
    border-radius: 20%;
    background-color: saddlebrown;
    width: 100%;
    aspect-ratio: 1/1;
    margin: 3px;
}

.SquareWrapper {
    aspect-ratio: 1;
}

.RectangleWrapper {
}

.outerBorder {
    background-color: wheat;
    padding: 5px;
    height: 100%;
}

.innerBorder {
    background-color: peru;
    height: 100%;
}

.DeluxePatchGrid {
    display: grid;
    grid-template-areas:
    'p1 p2 p3'
    'p8 em p4'
    'p7 p6 p5';
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.OoblongPatchGrid {
    display: grid;
    grid-template-areas:
    'p1 p2 p3'
    'p6 p5 p4';
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}


.RoundPatchGrid {
    display: grid;
    grid-template-areas:
    'p1 p2'
    'p4 p3';
    height: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.PatchBed {
    display: flex;
    border: 2px dashed wheat;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 5%;
    position: relative;
}

.clickableBed {
    background-color: transparent;
    border: none;
    padding: 0;
    aspect-ratio: 1;
}

.clickableBed:nth-child(1) {
    grid-area: p1;
}

.clickableBed:nth-child(2) {
    grid-area: p2;
}

.clickableBed:nth-child(3) {
    grid-area: p3;
}

.clickableBed:nth-child(4) {
    grid-area: p4;
}

.clickableBed:nth-child(5) {
    grid-area: p5;
}

.clickableBed:nth-child(6) {
    grid-area: p6;
}

.clickableBed:nth-child(7) {
    grid-area: p7;
}

.clickableBed:nth-child(8) {
    grid-area: p8;
}

.clickableBed:nth-child(9) {
    grid-area: em;
}

.emptyBed {
    border-radius: 20%;
    background-color: saddlebrown;
    width: 100%;
    aspect-ratio: 1/1;
    margin: 3px;
}

.plantIconBed {
    position: relative;
    width: auto;
    padding: 10%;
    border-radius: 10%;
    height: 100%;
}

.plantImage {
    border-radius: 10%;
}

.selectedBed {
    background-color: white;
}

.isGrown {
    background-color: #2dd36f;
}

.isWilting {
    background-color: var(--ion-color-wilt);
}

.isDead {
    background-color: FireBrick;
}

.isDead > img {
    filter: grayscale(100%);
}

.plantSomethingButton {
    display: block;
}

.buttonPanel {
    display: grid;
    grid-template-columns: 1fr 1fr 52px;
    padding-top: var(--SPACING-S);
    padding-bottom: var(--SPACING-S);
    grid-row: 2;
    grid-column: 1;
    animation: fadein 0.1s linear forwards;
    opacity: 0;
}

.moreOptions {
    grid-column-start: -2;
    grid-column-end: -1;
    grid-row: 1;
}


.buttonPanel > ion-button {
    /* opacity: 0; */
    /* animation: fadein 0.1s ease-in forwards; */
}

.allButtonPanel {
    display: grid;
    grid-template-columns: 1fr 1fr 52px;
    justify-content: center;
    padding-top: var(--SPACING-S);
    padding-bottom: var(--SPACING-S);
    flex-flow: wrap;
    grid-row: 2;
    grid-column: 1;
    animation: fadein 0.1s linear forwards;
    opacity: 0;
}

/* .allButtonPanel > ion-button {
    opacity: 0;
    animation: fadein 0.1s ease-in forwards;
} */

.fixedButton {
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 100%;
    margin: auto;
    padding: 2px;
}

.fertilizeProgressIcon {
    position: absolute;
    bottom: 0;
    left: 0;
}

.waterProgressIcon {
    position: absolute;
    bottom: 0;
    right: 70%;
}

.growProgressIcon {
    position: absolute;
    bottom: 0;
    right: 35%;
}

.plantInfoContainer {
    opacity: 0;
    animation: fadein 0.1s ease-in forwards;
}

.plantInfoContainer ion-item {
    animation: fadein 0.1s ease-in forwards;
}

.placeholderText {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255,255,255,0.15);
    font-size: 2em;
    animation: fadein 0.1s ease-in forwards;
}

.plantProgressContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px;
}

.plantProgressContainer ion-progress-bar {
    margin-top: 2px;
}

.plantSelection {
    display: none;
}

.searchbar.searchbar {
    padding-left: 0;
    padding-right: 0;
}

@media only screen and (min-width: 1120px) and (min-height: 720px) {
    .title {
        display: none;
    }

    .pageWrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 400px 1fr;
        height: calc(100% - 56px);
    }

    .plantInfoContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        column-gap: 5px;
        padding-left: 16px;
    }
    .plantSomethingButton {
        display: none;
    }

    .plantSelection {
        display: block;
        opacity: 0;
        animation: fadein 0.1s ease-in forwards;
    }

    .searchbar.searchbar {
        padding-top: 0;
        padding-left: unset;
        padding-right: unset;
    }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}