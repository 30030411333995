.pageBody {
    display: flex;
    flex-direction: column;
}

.patchesTab ion-content {
    --offset-top: 16px !important;
    --offset-bottom: 16px !important;
}

.addPatchButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.addPatchButton {
    margin: var(--SPACING-M);
    background-color: wheat;
    width: 240px;
}

.patchList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
}

.patchSlideItem {
    flex-basis: 450px;
    flex-grow: 1;
    flex-shrink: 1;
}

.patchDeleteButton {
    position: absolute;
    right: 0;
    top: 0;
}

.patchItem {
    display: grid;
    flex-direction: row;
    grid-template-columns: minmax(140px, 250px) minmax(140px, 400px);
    grid-gap: 20px;
    padding: var(--SPACING-M);
}

.patchItemPreview {
    margin-top: auto;
    margin-bottom: auto;
}

.patchItemText {
    width: 100%;
}

.GardenPatchPageModal {
    --width: 420px;
    --height: 100%;
    --backdrop-opacity: 0.5;
}

@media only screen and (min-width: 1120px) and (min-height: 720px) {
    .GardenPatchPageModal {
        --width: min(90%, 1200px);
        --height: 645px;
        --backdrop-opacity: 0.5;
    }
}
.spacer {
    padding-top: 0px;
}

.smallText {
    font-size: small !important;
}